<template>
  <AuthLayout>
    <v-card class="auth-card">
      <VFadeTransition mode="in-out">
        <VCol class="py-0 mb-8">
          <p
            :class="['text-center', $vuetify.breakpoint.xs ? 'text-h6' : 'text-h4']"
            class="font-weight-bold mb-4"
          >
            {{ $t("auth.titleRegistration") }}
          </p>
          <div class="d-flex align-center justify-center">
            <span class="subheader-tag"> {{ $t("landing.title") }}</span>
          </div>
        </VCol>
      </VFadeTransition>

      <VForm @submit.prevent="onRegister">
        <VRow align="center">
          <VCol cols="12" class="py-0 mb-5">
            <VTextField
              :maxLength="30"
              outlined
              :label="$t('auth.company_name')"
              dense
              height="48px"
              class="auth-input"
              hide-details="auto"
              color="success"
              v-model="user.company"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="6" class="py-0 mb-5">
            <VTextField
              @keypress="validationForName($event)"
              @paste="validationForName($event)"
              :maxLength="30"
              outlined
              :label="$t('form.name')"
              :error-messages="nameErrors"
              dense
              height="48px"
              hide-details="auto"
              color="success"
              class="auth-input"
              :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
              v-model="user.name"
            />
          </VCol>
          <VCol cols="6" class="py-0 mb-5">
            <VTextField
              @keypress="validationForSurname($event)"
              @paste="validationForSurname($event)"
              :maxLength="30"
              outlined
              dense
              height="48px"
              :label="$t('form.surname')"
              :error-messages="surnameErrors"
              hide-details="auto"
              color="success"
              class="auth-input"
              :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
              v-model="user.surname"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="py-0 mb-5">
            <v-select
              @keypress="validationForWarehouses($event)"
              @paste="validationForWarehouses($event)"
              :maxLength="30"
              :items="deportList"
              item-text="name"
              item-value="uuid"
              :hide-details="!deportErrors.length"
              :error-messages="deportErrors"
              :label="$t('form.storage')"
              dense
              height="48px"
              outlined
              :no-data-text="$t('table.noData')"
              color="success"
              class="auth-select"
              item-color="success"
              v-model="user.uuid_deport"
            >
              <template v-slot:item="{ item }">
                <div style="width: 100%">
                  {{ item.name }}
                  <br />
                  <span v-if="item.description" style="font-size: .875rem; opacity: 0.6;">
                    ({{ item.description }})
                  </span>
                  <v-divider class="pb-1"></v-divider>
                </div>
              </template>
            </v-select>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="6" class="py-0 mb-5">
            <VTextField
              @keypress="validationForPhoneNumber($event)"
              @paste="validationForPhoneNumber($event)"
              :maxLength="20"
              class="inputPhone auth-input"
              outlined
              :label="$t('auth.phone')"
              :error-messages="phoneErrors"
              :hide-details="!phoneErrors.length"
              dense
              height="48px"
              prefix="+38"
              color="success"
              v-model="user.phone"
            />
          </VCol>
          <VCol cols="6" class="py-0 mb-5">
            <VTextField
              @keypress="validationForEmail($event)"
              @paste="validationForEmail($event)"
              :maxLength="50"
              outlined
              :label="$t('auth.email')"
              :error-messages="emailErrors"
              :hide-details="!emailErrors.length"
              dense
              height="48px"
              color="success"
              v-model="user.email"
              class="auth-input"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="py-0 mb-5">
            <VTextField
              @keypress="validationForPassword($event)"
              @paste="validationForPassword($event)"
              :maxLength="40"
              outlined
              :label="$t('form.password')"
              dense
              height="48px"
              :type="passwordVisible ? 'text' : 'password'"
              :error-messages="passwordErrors"
              @click:append="passwordVisible = !passwordVisible"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              autocomplete="new-password"
              hide-details="auto"
              color="success"
              v-model="user.password"
              class="auth-input"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="py-0 mb-5 d-flex align-center">
            <v-checkbox color="success" hide-details="auto" v-model="rights" />
            <span style="margin-top: 7px" class="body-2"
              >{{ $t("auth.user_agreement_text") }}
              <a
                href="https://my.newsend.ua/pdf/politics.pdf"
                target="_blank"
                class="text-decoration-underline"
                style="color: #4FAE3B"
              >
                {{ $t("auth.user_agreement_link") }}</a
              ></span
            >
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="text-center py-0 mb-6">
            <VBtn
              color="#4FAE3B"
              class="text-white text-transform-none"
              depressed
              large
              type="submit"
              :loading="loading"
              :disabled="loading || !rights"
              block
              style="font-size: 16px; font-weight: 500;"
            >
              {{ $t("auth.btnRegister") }}
            </VBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="text-center py-0 d-flex align-center justify-center" no-gutters>
            <p class="mb-0 text-body-2">{{ $t("auth.already_registered") }}</p>
            <VBtn
              color="#4FAE3B"
              text
              @click="redirectToLogin"
              class="font-weight-medium text-transform-none px-2"
            >
              {{ $t("auth.link_to_register") }}
            </VBtn>
          </VCol>
        </VRow>
      </VForm>
    </v-card>
  </AuthLayout>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import * as actionTypes from "@/store/modules/auth/types/actions";
import { mapActions, mapGetters } from "vuex";
import AuthLayout from "./authLayout/AuthLayout.vue";
import lang from "../../mixins/language";
import notifications from "../../mixins/notifications";
import passwordMask from "../../validators/passwordMask";
import phoneMask from "../../validators/phoneMask";
import activationInfo from "./ActivationInfo.vue";
import {
  validationForName,
  validationForSurname,
  validationForPhoneNumber,
  validationForEmail,
  validationForWarehouses,
  validationForPassword
} from "@/mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";
import EventBus from "@/events/EventBus";

export default {
  name: "RegisterComponent",
  mixins: [lang, validationMixin, notifications],
  components: {
    AuthLayout,
    activationInfo
  },
  validations: {
    user: {
      uuid_deport: { required },
      email: { required, email },
      password: { required, passwordMask },
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      phone: { required, phoneMask }
    }
  },
  data: () => ({
    rights: false,
    onEngText: true,
    passwordVisible: false,
    repeatPasswordVisible: false,
    loading: false,
    deportList: [],
    user: {
      uuid_deport: "",
      company: "",
      email: "",
      name: "",
      surname: "",
      phone: "",
      password: "",
      isCRM: false
    },
    enteredEmail: ""
  }),
  mounted() {
    this.getDeportsList();
  },
  methods: {
    validationForPassword,
    validationForWarehouses,
    validationForPhoneNumber,
    validationForSurname,
    validationForName,
    validationForEmail,
    ...mapActions("auth", {
      register: actionTypes.REGISTER,
      requestDeports: actionTypes.DEPORTS
    }),
    async getDeportsList() {
      try {
        const data = await this.requestDeports();
        this.deportList = data.data.object;
        if (!this.deportList) {
          this.deportList = [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    async onRegister() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.user.name);
          formData.append("uuid_deport", this.user.uuid_deport);
          formData.append("surname", this.user.surname);
          formData.append("company", this.user.company);
          formData.append("email", this.user.email);
          formData.append("phone", `+38${this.user.phone}`);
          formData.append("password", this.user.password);
          formData.append("lang", this.currentLanguage.lang);
          formData.append("type", this.user.isCRM ? "crm" : "client");
          if (this.user.date_crm_end) {
            formData.append("date_crm_end", this.user.date_crm_end);
          }
          formData.append("url_landing", `https://${window.location.host}/activation/`);
          await this.register(formData);
          this.enteredEmail = this.user.email ? this.user.email : "";
          await this.redirectToLogin();
          EventBus.$emit("success-registration", this.enteredEmail);
          this.setSuccessNotification(this.$t("auth.successfulRegistration"));
          this.clearUser();
          this.$v.$reset();
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e.error.description);
        }
      }
    },
    clearUser() {
      Object.keys(this.user).forEach(key => {
        this.user[key] = "";
      });
    },
    redirectToLogin() {
      this.$router.push("/").catch(() => {});
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    deportErrors() {
      const errors = [];
      if (!this.$v.user.uuid_deport.$dirty) {
        return errors;
      }
      !this.$v.user.uuid_deport.required && errors.push(this.$t("form.errors.ChoseDeport"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required && errors.push(this.$t("form.errors.EmailRequired"));
      !this.$v.user.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      !this.$v.user.password.required && errors.push(this.$t("form.errors.PasswordRequired"));
      !this.$v.user.password.passwordMask && errors.push(this.$t("form.errors.PasswordMustBeLike"));
      return errors;
    },
    passwordRepeatErrors() {
      const errors = [];
      if (!this.$v.user.passwordRepeat.$dirty) {
        return errors;
      }
      if (!this.$v.user.passwordRepeat.required) {
        errors.push(this.$t("form.errors.ConfirmPassRequired"));
        return errors;
      }
      if (!this.$v.user.passwordRepeat.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));
        return errors;
      }
      !this.$v.user.passwordRepeat.sameAsPassword &&
        errors.push(this.$t("form.errors.PasswordsDontMatch"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      !this.$v.user.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      !this.$v.user.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      !this.$v.user.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      !this.$v.user.phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    }
  }
};
</script>
